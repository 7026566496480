export const isDev = true;

export const wordpressURL = isDev
  ? process.env.REACT_APP_STAGING_POLICY_URL
  : process.env.REACT_APP_PRODUCTION_POLICY_URL;

export const amplitudeKey = isDev
  ? process.env.REACT_APP_AMPLITUDE_DEV
  : process.env.REACT_APP_AMPLITUDE_PROD;

export const currentEnv = "staging-";

export const staticsite = "staging.";