export class NewsItem {
  initialize(data, index) {
    this.id = index;
    this.collectionSourceId = data.collectionSourceId;
    this.collectionId = data.collectionId;
    this.entityName = data.entityName;
    this.name = data.name;
    this.datePublishedDisplayText = data.datePublishedDisplayText;
    this.url = data.url;
    this.corpusUrl = data.corpusUrl;
    this.isCitationAvailable = data.isCitationAvailable;
    this.isSummaryAvailable = data.isSummaryAvailable;
    this.mediaBiasRating = data.mediaBiasRating;
    this.score = data.score;
    this.imageUrl = data.imageUrl;
    this.notes = data.notes;
    this.publishingDate = data.publishingDate;
    this.datePublishedDisplayText = data.datePublishedDisplayText;
    this.openGraphImage = data.openGraphImage;
    this.isInternalArticle = data.isInternalArticle;
    this.paywall = data.hasPaywall;
    this.partner = data.isPartner;
    return this;
  }
}
