import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  CardMedia,
  Divider,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import NewsguardScore from "components/base/NewsguardScore";
import defaultBackground from "assets/images/image-placeholder.png";
import {
  setCollectionId,
  setCollectionSourceId,
  setEntityName,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSourceUrl,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { useDispatch } from "react-redux";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";
import { HiOutlineLockOpen, HiOutlineLockClosed } from "react-icons/hi2";

const NewsCard = ({ index, item, cardRef }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSpecialIndex = index % 21 === 0;

  const imageUrl = item?.openGraphImage?.contentUrl
    ? item?.openGraphImage?.contentUrl
    : defaultBackground;

  const dispatch = useDispatch();

  const openSourceSidebar = () => {
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(item.corpusUrl));
    dispatch(setEntityName(item.entityName));
  };

  const openSidebar = () => {
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    dispatch(setCollectionSourceId(item.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: item.entityName,
        title: item.name,
        published_date: item.datePublishedDisplayText,
        url: item.url,
      })
    );
    dispatch(setCollectionId(item.collectionId));
    dispatch(setIsCitationAvailable(item.isCitationAvailable));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(item.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const handleTitleClick = () => {
    let newUrl = item.url;
    if (newUrl.includes("foreignpolicy.com")) {
      newUrl += "?auth=sooth";
    }
    if (item.isInternalArticle){
      newUrl =
        window.location.origin +
        "/afp-article/" +
        item.url.substring(item.url.lastIndexOf("/") + 1);
    }  
    window.open(newUrl, "_blank");
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          openSidebar();
          setIsHovered(false);
        }}
        sx={{
          cursor: "pointer",
          height: "40%",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "#e0f2fe",
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
      >
        {item.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              width: 17,
              height: 17,
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  width: 17,
                  height: 17,
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Grid
      ref={cardRef}
      container
      spacing={2}
      sx={{ height: isSpecialIndex ? "36vh" : "20vh" }}
    >
      {isSpecialIndex && (
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: "30vh", width: "100%", borderRadius: 1 }}
            image={imageUrl}
            alt="Article Image"
          />
        </Grid>
      )}
      <Grid
        item
        xs={isSpecialIndex ? 6 : 9}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          py: 1,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={openSourceSidebar}
          sx={{
            borderRadius: 1,
            px: "1rem",
            py: 0.4,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#e0e9ef",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "rgb(126,127,126)",
              }}
            >
              {item.entityName}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {item.mediaBiasRating && (
                <Grid item>
                  <AllSidesRating activeCategory={item.mediaBiasRating} />
                </Grid>
              )}
              {item.score && (
                <Grid item>
                  <NewsguardScore score={Number(item.score)} size="small" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ px: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Typography
              onClick={() => handleTitleClick()}
              variant={isSpecialIndex ? "h4" : "body4"}
              sx={{
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: { xs: 3, sm: 5 },
                WebkitBoxOrient: "vertical",
                // lineHeight: { xs: "2vh", sm: "2vh" },
                maxWidth: "90%",
                "&:hover": {
                  color: "rgb(18,89,151)",
                },
              }}
            >
              {item.name}
            </Typography>
            {item.partner ? (
              <Tooltip title="We removed the paywall for you!">
                <Box sx={{ cursor: "pointer" }}>
                  <HiOutlineLockOpen />
                </Box>
              </Tooltip>
            ) : (
              item.paywall && (
                <Tooltip
                  placement="bottom-start"
                  title="This source may limit your access without a subscription"
                >
                  <Box sx={{ cursor: "pointer" }}>
                    <HiOutlineLockClosed />
                  </Box>
                </Tooltip>
              )
            )}
          </Box>
          {item.snippet && (
            <Typography
              sx={{
                "&:hover": {
                  color: "rgb(18,89,151)",
                },
              }}
            >
              {item.snippet}
            </Typography>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography variant="body2" color="textSecondary">
              {item.datePublishedDisplayText &&
                `${item.datePublishedDisplayText}`}
            </Typography>
            <BookmarkOptions />
          </Stack>
        </Box>
        <Divider />
      </Grid>
      {!isSpecialIndex && (
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: "12vh", borderRadius: 1 }}
            image={imageUrl}
            alt="Article Image"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default NewsCard;
